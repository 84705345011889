<template>
  <div id="Screenshoots">
    <!-- ***** App Screenshots Area Start ***** -->
    <section class="bg-light" id="screenshot">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-heading text-center">
              <h2>OVERVIEW</h2>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <carousel
              :autoplay="true"
              :responsive="{
                0: { items: 1, nav: false },
                600: { items: 3, nav: true },
              }"
            >
              <!-- <img v-for="(img, index) in images" :src="img" :key="index" /> -->
              <v-lazy-image
                v-for="(img, index) in images"
                :src="img"
                :key="index"
              />
            </carousel>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const carousel = () => import("vue-owl-carousel");
const VLazyImage = () => import("v-lazy-image");
export default {
  name: "Screenshoots",
  components: {
    carousel,
    VLazyImage,
  },
  data: function() {
    return {
      images: [
        "assets/images/over-view/1.jpg",
        "assets/images/over-view/2.jpg",
        "assets/images/over-view/3.jpg",
        "assets/images/over-view/4.jpg",
        "assets/images/over-view/5.jpg",
        "assets/images/over-view/6.jpg",
        "assets/images/over-view/7.jpg",
      ],
    };
  },
};
</script>

<style scoped>
.v-lazy-image {
  filter: blur(10px);
  transition: filter 3s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
